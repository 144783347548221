import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import { HeroBackgroundImage } from '../../components/images';
import { renderFlatRichText } from '../../components/rich-text';
import RelatedResources from '../../components/related-resources';
import './index.scss';

function slugify (str) {
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-');
}

export default function Resource ({ path, data }) {
  const {
    title, category, heroText, heroSubtitle, modules, metaDescription, heroBackgroundImage,
    relatedResourcesHeading, relatedResources, ogImage
  } = data.contentfulResource;

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <section className={`hero hero-resource hero-resource-${slugify(category)}`}>
        <HeroBackgroundImage heroBackgroundImage={heroBackgroundImage} />
        <div className="container hero-resource__inner">
          <p className="hero-category-subtitle">{category}</p>
          <h3>{renderFlatRichText({ node: heroText })}</h3>
          <p className="hero-category-subtitle">{heroSubtitle?.heroSubtitle}</p>
        </div>
      </section>

      {renderModules(modules)}

      <RelatedResources
        relatedResourcesHeading={relatedResourcesHeading}
        relatedResources={relatedResources}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
query ResourceById($id: String!) {
  contentfulResource(id: {eq: $id}) {
    relatedResourcesHeading, category
    title { title }
    heroSubtitle { heroSubtitle }
    heroText { raw }

    ogImage: heroBackgroundImage {
      ...pageOgImageFields
    }

    heroBackgroundImage {
      ...heroBackgroundImageFields
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulBeyondZeroHarmModule {
        ...beyondZeroHarmModuleFields
      }
      ... on ContentfulContactModule {
        ...contactModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulDownloadModule {
        ...downloadModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulFeaturedResourcesModule {
        ...featuredResourcesModuleFields
      }
      ... on ContentfulFeaturedSolutionsModule {
        ...featuredSolutionsModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulLeadershipModule {
        ...leadershipModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }
}
`;
